import {LitElement, html, unsafeCSS} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import _st from './st';
import style from './st.css';

const st = _st as any;

type PeakLabeled = {mzValue: number, intensityValue: number};

@customElement('omnicarb-visualizer')
export class OmniCarbVisualizer extends LitElement {
  static styles = unsafeCSS(style.toString());

  private _data: string = '';

  @property({type: String})
  get data(): string {
    return this._data;
  }
  set data(value: string) {
    if (this._data === value) {
      return;
    }

    this._data = value;
    const pLabeledList = this.parseJson(this._data);
    if (pLabeledList !== null && this.rootElement !== null) {
      this.renderChart(pLabeledList);
    }
  }

  @query('#root')
  rootElement: HTMLDivElement;

  private parseJson(json: string): PeakLabeled[] | null {
    try {
      return JSON.parse(json) as PeakLabeled[];
    } catch(e) {
      console.error(e);
      return null;
    }
  }

  private renderChart(pLabeledList: PeakLabeled[]) {
    st.setRootElement(this.rootElement);

    const jsonObject = {
      spectrumId: "TestID",
      mzStart: 0,
      mzStop: 1000,
      peaks: pLabeledList.map(pl => ({mz: pl.mzValue, intensity: pl.intensityValue}))
    };

    const yAxis = pLabeledList.map(pl => pl.intensityValue);

    const largest = Math.max.apply(Math, yAxis);

    const chart = st.chart
      .ms()
      .legend(false)
      .xlabel("m/z")
      .ylabel("Abundance")
      .labels(false)
      .margins([50, 80, 80, 120]);

    chart.render("#stgraph");               // render chart to id 'stgraph'

    const set = st.data.set()               // data type (set)
            .ylimits([0, largest])          // y-axis limits
            .x("peaks.mz")                  // x-accessor
            .y("peaks.intensity")           // y-accessor
            .title("spectrumId");           // id-accessor

    chart.load(set);                        // bind the data set
    set.add([jsonObject], []);
  }

  firstUpdated() {
    const pLabeledList = this.parseJson(this._data);
    if (pLabeledList !== null && this.rootElement !== null) {
      this.renderChart(pLabeledList);
    }
  }

  render() {
    return html`
      <div id="root" style="position: relative;">
        <div id="stgraph" style="width: 600px; height: 450px;"></div>
      </div>
    `;
  }
}

